import './App.css';
import React from "react";
import {Routes, Route} from 'react-router-dom'
import HomePage from './components/pages/HomePage/HomePage';
import LoginPage from './components/pages/LoginPage/LoginPage';
import RegisterPage from './components/pages/RegisterPage/RegisterPage';
import { AuthProvider } from './context/authContext';
import { ProtectedRoute } from './components/ProtectedRoutes';

function App() {

  return (
    <div className="bg-slate-100 h-screen text-white flex">
      <AuthProvider>
    <Routes>
      <Route path = "/" element={
       <ProtectedRoute>
        <HomePage/>
       </ProtectedRoute>    
      }/>
      <Route path = "/login" element={<LoginPage/>}/>
      <Route path = "/register" element={<RegisterPage/>}/>
    </Routes>
    </AuthProvider>
   </div>
  );
}


export default App;
