import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/authContext'
import { Navigate } from 'react-router-dom';
import { database } from "../../../firebase";
import { ref, onValue, off, update } from "firebase/database";
import "./HomePage.css";
import { TextField, Button } from '@mui/material';
import Logo from '../../logo/logo.svg';


export default function HomePage() {
    const db = database;
    const {user, logout, loading } = useAuth();
    const [userData, setUserData] = useState();
    const [configData, setConfigData] = useState();
    const [configLoaded, setConfigLoaded] = useState(false);
    
    const handleLogout = async ()=>{
        await logout();
        Navigate("/login");
        if(loading)return <h1>loading...</h1>
    }
    
    useEffect(() => {
        onValue(ref(db, `users/${user.uid}`), (snapshot) => {
          const dataFromSnapshot = snapshot.val();
          if (dataFromSnapshot != null) {
            setUserData({
              nombre: dataFromSnapshot.nombre,
              db: dataFromSnapshot.db
            });
          }
        });
    
    
        return () => {
          off(ref(db, `users/${user.uid}`));
        };
      }, [db, user.uid, userData]);

      useEffect(() => {
        if (userData && userData.db) {
          onValue(ref(db, `SistemaAleatorio/${userData.db}/config`), (snapshot) => {
            const dataFromSnapshot = snapshot.val();
            if (dataFromSnapshot != null) {
              setConfigData(dataFromSnapshot);
              setConfigLoaded(true);
            }
          });
        }
      
        return () => {
          if (userData && userData.db) {
            off(ref(db, `SistemaAleatorio/${userData.db}/config`));
          }
        };
      }, [db, userData]);

    return (
        <div className='w-full container m-auto'>
            <div></div>
      <div className='titulo'>Hola {userData && userData.nombre}!</div>
      <div  className='subtitulo'>Bienvenido al panel de configuración</div>
      {configLoaded && (
       
        <form className='form bg-white shadow-md'> 
        <img className="logoHome" alt="" src={Logo} />
    
          <TextField
            className='w-full setField'
            type="text"
            label="Titulo"
            value={configData.titulo}
            onChange={(e) =>
              update(ref(db, `SistemaAleatorio/${userData.db}/config`), {
                titulo: e.target.value
              })
            }
          />
        
          <TextField
          className='w-full setField'
            type="text"
            label="Sorteo"
            value={configData.sorteo}
            onChange={(e) =>
              update(ref(db, `SistemaAleatorio/${userData.db}/config`), {
                sorteo: e.target.value
              })
            }
          />
           
          <TextField
          className='w-full setField'
            type="text"
            label="Tiempo del concurso"
            value={configData.tiempo}
            onChange={(e) =>
              update(ref(db, `SistemaAleatorio/${userData.db}/config`), {
                tiempo: e.target.value
              })
            }
          />
             
          <TextField
          className='w-full setField'
            type="text"
            label="Tiempo de espera de participantes"
            value={configData.tiempoParticipantes}
            onChange={(e) =>
              update(ref(db, `SistemaAleatorio/${userData.db}/config`), {
                tiempoParticipantes: e.target.value
              })
            }
          />
        </form>

      )}

      <Button className='enterButton' fullWidth size="large" type="submit" variant="contained" onClick={handleLogout}>Cerrar Sesion</Button>
    </div>
    )
}
