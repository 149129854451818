import React from 'react'
import { Button, TextField, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/authContext';
import { useState } from 'react';
import { ref, set } from "firebase/database";
import { database } from "../../../firebase";
import Logo from '../../logo/logo.svg';

export default function RegisterPage() {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [user, setUser] = useState({
      email: "",
      password: "", 
  })
    
    const {signup} = useAuth();

    const handleChange = ({target: {name, value}}) => {
        setUser ({...user, [name]: value});
      }
        
      const handleClick = async (e) => {
    
        e.preventDefault();
    
      try {
        const userCredentials = await signup(user.email, user.password);
        const data = userCredentials.user;
        const db = database;
        set(ref(db, `users/${data.uid}`), {
          nombre : user.name,    
          db:user.db
        });
    
        navigate("/");
    
      } catch (error) {
        
        console.log(error.code);
        if (error.code === "auth/invalid-email"){
          setError("Correo invalido.");
        }
        if (error.code === "auth/weak-password"){
          setError("La contraseña debe contener minimo 6 caracteres.");
        }
        if (error.code === "auth/internal-error"){
          setError("Complete todos los campos requeridos.");
        }
        if (error.code === "auth/email-already-in-use"){
          setError("Este correo ya existe.");
        }  
      }    
      };
  
    return (

      <div className='w-full container m-auto'>
           <img className="logo" alt="" src={Logo} />
            <form className='form bg-white shadow-md'> 
            {error && <Alert className='setField' severity="error">{error}</Alert>}
            <TextField className='w-full setField' name="name" label="Nombre" onChange={handleChange}/>
            <TextField className='w-full setField' name="email" label="Correo Electronico" onChange={handleChange} />
            <TextField className='w-full setField' name="password" label="Contraseña" type="password" onChange={handleChange} />
            <TextField className='w-full setField' name="db" label="Nombre de base de datos" onChange={handleChange}/>

            <Button className='enterButton' fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                Registrar
            </Button>
            </form>   
            <div className='footer'>Creado por <a className="url" href='https://www.grupopeci.com'>Grupo PECI S.A.S</a> </div>
        </div>
    )
}


