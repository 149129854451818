import React from 'react'
import { useAuth } from '../../../context/authContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, TextField } from '@mui/material';
import "./LoginPage.css";
import Logo from '../../logo/logo.svg';

export default function LoginPage() {

    const navigate = useNavigate();
    const [error, setError] = useState();
    const [user, setUser] = useState({
        email: "",
        password: "", 
    })

    const { login } = useAuth();
    
    const handleChange = ({target: {name, value}}) => {
        setUser ({...user, [name]: value});
    }

  const handleClick = async (e) => {
    e.preventDefault();

  try {
    const userCredentials = await login(user.email, user.password);
    navigate("/");

  } catch (error) {
    
    console.log(error.code);
    if (error.code === "auth/invalid-email"){
      setError("Correo invalido.");
    }
    if (error.code === "auth/user-not-found"){
      setError("Este usuario no existe.");
    }
    if (error.code === "auth/wrong-password"){
      setError("La contraseña es incorrecta");
    }
  }    
  };


    return (
        <div className=' w-full container m-auto'>
           <img className="logo" alt="" src={Logo} />
            <form className='form bg-white shadow-md'> 
            {error && <Alert className='setField' severity="error">{error}</Alert>}
            <TextField className='w-full setField' name="email" label="Correo Electronico" onChange={handleChange} />
            <TextField className='w-full setField' name="password" label="Contraseña" type="password"onChange={handleChange}/>
            <Button className='enterButton' fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                Ingresar
            </Button>
            </form>   

            <div className='footer'>Creado por <a className="url" href='https://www.grupopeci.com'>Grupo PECI S.A.S</a> </div>
        </div>
    )
}
